import { graphqlQuery } from "../../api/apollo.setup";
import { GET_COMPANIES } from "../../api/graphql/company";
import {
  CompanyFilters,
  GetCompaniesRequest,
  GetCompaniesResponse,
} from "../../types/company.type";
import { Dispatch } from "../store";
import { CompaniesActions } from "./companies.slice";

export const getCompanies =
  (filters?: CompanyFilters, currentPage?: number) =>
  async (dispatch: Dispatch) => {
    try {
      dispatch(CompaniesActions.fetchCompaniesRequest());
      const res = await graphqlQuery<GetCompaniesResponse, GetCompaniesRequest>(
        GET_COMPANIES,
        { GetCompaniesInput: { currentPage, filters } }
      );
      dispatch(CompaniesActions.fetchCompanies(res.data.getCompanies));
    } catch (error) {
      dispatch(CompaniesActions.fetchCompaniesFail(error));
    }
  };
