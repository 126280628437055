import { useState } from "react";
import { LazyLoadImage } from "react-lazy-load-image-component";

import Eticket from "../../Eticket";
import styles from "./../index.module.scss";

const HomeText = () => {
  const [expanded, setExpanded] = useState(false);

  return (
    <div className={styles.outerHomeContainer}>
      <div className={styles.homeContainer}>
        <h2
          id={styles.homeContainerTitle}
          className={`${styles.title} ${styles.homeContainerTitle}`}
        >
          Pourquoi avoir créé
          <Eticket
            text="Swala"
            color="var(--color-dark-1) !important"
            backgroundColor="var(--color-primary)"
          />
        </h2>
        {!expanded && (
          <p className={styles.paragraph}>
            Depuis la crise sanitaire, le secteur du médical est en difficulté
            au niveau du recrutement. Mais une autre problématique émerge : le
            secteur de la santé n'est pas non plus doté de la marque employeur.
            Aujourd'hui, les professionnels de santé ne bénéficient pas d'outils
            pour mettre en avant leur établissement. C'est pourtant un élément
            important lorsqu'un candidat prend la décision de changer de métier
            et de projet professionnel. Nous avons ...
          </p>
        )}

        <div
          className={expanded ? styles.closed : styles.open}
          onClick={() => {
            setExpanded(!expanded);
          }}
        >
          <LazyLoadImage src={"/assets/images/expand.svg"} />
        </div>
      </div>
      <div
        className={`${styles.expandedHomeContainer} ${
          expanded ? "" : "nodisplay"
        }`}
      >
        <div className={styles.expandedParagraph}>
          <p className={styles.expanded}>
            Depuis la crise sanitaire, le secteur du médical est en difficulté
            au niveau du recrutement. Mais une autre problématique émerge : le
            secteur de la santé n'est pas non plus doté de la marque employeur.
            Aujourd'hui, les professionnels de santé ne bénéficient pas d'outils
            pour mettre en avant leur établissement. C'est pourtant un élément
            important lorsqu'un candidat prend la décision de changer de métier
            et de projet professionnel. Nous avons donc décidé de créer SWALA,
            la première plateforme de recrutement 100% dédiée aux acteurs du
            médical.
            <br />
            <br />
            Nous dédions cette plateforme de recrutement à tous les
            établissements de santé, parce que nous savons que le médical est un
            secteur en pleine croissance et créateur d’emploi. En France, le
            secteur du médical fait partie du top 5 des secteurs dans lesquels
            on recrute le plus avec plus de 2 230 000 professionnels de santé et
            plus de 60 000 entreprises spécialisées dans la santé.
            <br />
            <br />
            Swala, c’est LA nouvelle plateforme de recrutement dédiée aux
            professionnels de la santé ! Avec Swala, nous souhaitons proposer
            une plateforme de recrutement faisant évoluer les codes de l’offre
            d’emploi du secteur médical. Pour cela, nous avons imaginé une
            plateforme de recrutement 360° pour proposer une offre adaptée et
            attractive selon vos besoins. Parce que c'est à notre tour de
            prendre soin du secteur de la santé et de vous apporter des conseils
            de qualité en recrutement !
            <br />
            <br />
            <h2 className={`${styles.title} ${styles.center}`}>
              Pour les candidats en recherche
              <Eticket
                text="d'emploi"
                color="var(--color-dark-1) !important"
                backgroundColor="var(--color-primary)"
              />
            </h2>
            Vous êtes candidat et vous avez un nouveau projet professionnel dans
            le secteur du médical ? Que vous recherchiez un CDI ou un CDD,
            découvrez les entreprises du secteur médical qui recrutent du
            personnel de santé dans leur équipe ! Parcourez les nombreuses
            offres d'emploi postées par les professionnels de santé et découvrez
            leur page entreprise pour en savoir davantage sur leur
            établissement, leur équipe et leurs missions !
            <br />
            <br />
            Créez votre profil candidat pour postuler et suivre vos
            candidatures, et trouvez des offres d'emploi santé qui vous
            correspondent grâce à l'algorithme de matching. Cet algorithme
            pourra vous aider dans votre recherche d'emploi car il fera une
            évaluation automatique de votre compatibilité avec l'entreprise et
            les missions qu'elle propose !
            <br />
            <br />
            Vous recherchez un CDI ou un CDD en tant que directeur
            d'établissement médico-social à Saint-Denis ou aux alentours de
            Paris ? Rien de plus simple : rendez-vous dans "Offres" et filtrez
            selon vos critères de recherches : profession (directeur
            d'établissement médico-social), localisation (Saint-Denis), salaire
            souhaité, type de contrat (CDI et/ou CDD), secteur (Hôpitaux et
            centres de soins), date de publication et langues.
            <br />
            <br />
            <h2 className={`${styles.title} ${styles.center}`}>
              Et pour les entreprises en manque de
              <Eticket
                text="personnel"
                color="var(--color-dark-1) !important"
                backgroundColor="var(--color-primary)"
              />
            </h2>
            Vous êtes en manque de personnel de santé ? Pas besoin de faire
            appel à un cabinet de recrutement, complétez votre équipe médicale
            grâce à Swala !
            <br />
            <br />
            Avec l'abonnement Jobslot, déposez une offre d'emploi médicale et
            accédez à un outil de gestion pour les candidatures (vous pouvez
            cumuler plusieurs abonnements pour déposer plusieurs offres d’emploi
            santé en même temps).
            <br />
            <br />
            L'abonnement Entreprise vous permettra de mettre en avant votre
            établissement en créant votre page entreprise. Grâce à cette page,
            présentez votre établissement (métier, services, missions), votre
            secteur d'activité et vos valeurs. En plus de ça, déposez autant
            d'offres d'emploi que vous le souhaitez ! Par exemple, si vous
            recherchez un directeur d'établissement médico-social en CDI ainsi
            qu'un chef de service médical en CDD à Paris, vous pourrez poster
            vos deux offres d'emploi simultanément et suivre les candidatures
            grâce à l'outil de gestion.
            <br />
            <br />
            Si vous souhaitez avoir accès à de nombreux profils en illimité,
            l'abonnement CVthèque vous permettra de découvrir des profils de
            candidats ciblés grâce au système de matching.
            <br />
            <br />
          </p>
        </div>
      </div>
    </div>
  );
};

export default HomeText;
