import Link from "next/link";
import { LazyLoadImage } from "react-lazy-load-image-component";

import { useBreakpoint } from "../../../hooks/useBreakpoint";
import { Breakpoint } from "../../../types/enum.types";
import Eticket from "../../Eticket";
import styles from "./Contact.module.scss";

function Contact({ homepage = false }: { homepage?: boolean }) {
  const breakpoint = useBreakpoint();

  const isMedium =
    breakpoint.includes(Breakpoint.XS) && !breakpoint.includes(Breakpoint.LG);

  return (
    <div className={styles.contact_container}>
      <div>
        <div className={styles.contact_header}>
          <LazyLoadImage
            src="/assets/icons/stetoscope.svg"
            alt="stetoscope"
            width="100%"
            height="auto"
          />
          {isMedium && (
            <h2 className={styles.stetoscopeText}>
              <span>
                La santé demande de l’énergie, n’en perdez plus dans vos{" "}
                <Eticket
                  text="recherches"
                  backgroundColor="var(--color-dark-1)"
                  color="white"
                />{" "}
                !
              </span>{" "}
              <br /> Merci de prendre soin de nous, c’est à notre tour de le
              faire !
            </h2>
          )}
        </div>

        <div className={styles.contact_text}>
          <div>
            {!isMedium && (
              <h2 className={styles.stetoscopeText}>
                <span>
                  La santé demande de l’énergie, n’en perdez plus dans vos
                  <Eticket
                    text="recherches "
                    backgroundColor="var(--color-dark-1)"
                    color="white"
                  />{" "}
                  !
                </span>{" "}
                <br />
                <br />
                Merci de prendre soin de nous, c’est à notre tour de le faire !
              </h2>
            )}

            {!homepage && (
              <Link href="mailto:contact@swala.fr">
                <button className={styles.contact_button}>
                  Contactez-nous
                </button>
              </Link>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

export default Contact;
