import { useBreakpoint } from "../../../hooks/useBreakpoint";
import { Breakpoint } from "../../../types/enum.types";
import Eticket from "../../Eticket";
import OfferSearchInputs from "../../offers/utils/OfferSearchInputs";
import styles from "./HomeSearch.module.scss";

function HomeSearch() {
  const breakpoint = useBreakpoint();

  const isMedium =
    breakpoint.includes(Breakpoint.XS) && !breakpoint.includes(Breakpoint.LG);

  return (
    <div className={styles.home_search_container}>
      <div className={styles.home_search_interface}>
        <div className={styles.home_search_content}>
          <h1 className={styles.home_search_title}>
            Pour ne manquer aucune opportunité, juste
            <Eticket
              text="SWALA"
              backgroundColor="var(--color-dark-1)"
              color="white !important"
            />{" "}
            !
          </h1>
          <p>Swala, c'est tout simplement la santé concentrée !</p>
        </div>

        <div className={isMedium ? styles.gap : ""}>
          <div className={styles.home_search_image}></div>
        </div>
      </div>
      <div className={styles.offer_search_inputs}>
        <div>
          <p className={styles.offersSearchTitle}>
            Soignez votre recherche d’emploi santé avec SWALA !
          </p>
          <OfferSearchInputs version={1} />
        </div>
      </div>
    </div>
  );
}

export default HomeSearch;
