import "swiper/css";
import "swiper/css/pagination";

import { useRouter } from "next/router";
import { useEffect } from "react";
import { LazyLoadImage } from "react-lazy-load-image-component";
import { useDispatch, useSelector } from "react-redux";
import styled from "styled-components";
import { Navigation } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";

import { getCompanies } from "../../../redux/companies/companies.actions";
import { CompaniesActions } from "../../../redux/companies/companies.slice";
import { Dispatch, StoreState } from "../../../redux/store";
import { getCompanyImage } from "../../../utils/functions.utils";
import { ENTREPRISE_PAGE, ENTREPRISES_PAGE } from "../../../utils/pages";
import { formatSectors } from "../../../utils/utils";
import PrimaryButton from "../../buttons/PrimaryButton";
import Eticket from "../../Eticket";
import { RoundedSvg } from "../../home/utils/styles";
import CompanyCard from "../utils/CompanyCard";
import styles from "./Companies.module.scss";

const CardPosition = styled.div`
  width: 90%;
  margin-left: 10px;
  cursor: pointer;
  height: 100%;

  a {
    text-decoration: none;
    color: inherit;
  }
`;

const CarouselContainer = styled.div`
  width: 95%;
  margin: 15px 0 0 auto;
`;

const Button = styled(PrimaryButton)`
  font-family: var(--font-title-bold);
`;

const breakpoints = {
  0: {
    slidesPerView: 1.4,
    spaceBetween: 0,
  },
  768: {
    slidesPerView: 2.4,
    spaceBetween: 0,
  },
  1024: {
    slidesPerView: 3.2,
    spaceBetween: 0,
  },
  1200: {
    slidesPerView: 4.2,
    spaceBetween: 0,
  },
  3000: {
    slidesPerView: 4.2,
    spaceBetween: 0,
  },
  4000: {
    slidesPerView: 5,
    spaceBetween: 0,
  },
};

const Companies = () => {
  const dispatch = useDispatch<Dispatch>();
  const { companies, loading } = useSelector(
    (store: StoreState) => store.companies
  );

  const router = useRouter();

  const handleSeeAll = () => {
    router.push(ENTREPRISES_PAGE);
  };

  useEffect(() => {
    if (!loading) dispatch(getCompanies(undefined, 1));

    return () => {
      dispatch(CompaniesActions.resetCompanies());
    };
  }, []);

  const navigation = {
    nextEl: "#nextCompanyButton",
    prevEl: "#prevCompanyButton",
  };
  useEffect(() => {
    dispatch(getCompanies(undefined, 1));
  }, []);
  return (
    <div className={styles.latest_offers_container}>
      <div className={styles.latest_offers_header}>
        <div className={styles.latest_offers_header_left_part}>
          <RoundedSvg
            width="50px"
            color="var(--color-primary)"
            className={styles.roundedSVG}
          >
            <LazyLoadImage src="/assets/icons/bag.svg" alt="bag" />
          </RoundedSvg>
          <h2 className={styles.latest_offers_title}>
            LES DERNIÈRES
            <Eticket
              text="entreprises"
              color="var(--color-dark-1)"
              backgroundColor="var(--color-primary)"
            />{" "}
          </h2>
        </div>

        <div className={styles.latest_offers_header_right_part}>
          <Button
            label="Tout voir"
            width="100px"
            padding="10px"
            onClick={handleSeeAll}
          />
        </div>
      </div>

      {/* offers part */}
      <div className={styles.container}>
        <CarouselContainer>
          <Swiper
            threshold={10}
            slidesPerView={4.2}
            spaceBetween={0}
            navigation={navigation}
            className={styles.swiperWrapperClass}
            modules={[Navigation]}
            breakpoints={breakpoints}
          >
            {companies.map((company) => (
              <SwiperSlide key={company.id} className={styles.maxHeight}>
                <CardPosition>
                  <a
                    href={`${ENTREPRISE_PAGE}${company.slug}/`}
                    rel="noreferrer"
                  >
                    <CompanyCard
                      companyImage={getCompanyImage(company)}
                      companyName={company.name}
                      image={company.coverImagePath ?? ""}
                      location={company.offices?.[0]?.city ?? ""}
                      sector={formatSectors(company.sectors)}
                    />
                  </a>
                </CardPosition>
              </SwiperSlide>
            ))}
          </Swiper>
        </CarouselContainer>
      </div>
    </div>
  );
};

export default Companies;
