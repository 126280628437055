import "react-multi-carousel/lib/styles.css";

import React from "react";
import Carousel from "react-multi-carousel";
import styled from "styled-components";

import { Review } from "../../../types/review.type";
import TestimonialCard from "../utils/TestimonialCard";
import styles from "./Testimonials.module.scss";

const breakpoints = {
  xs: { max: 720, min: 0, items: 1 },
  md: { max: 1200, min: 721, items: 2 },
  lg: { max: 1600, min: 1201, items: 3 },
  xl: { max: 3000, min: 1601, items: 4 },
};

const responsive = {
  largeScreen: {
    breakpoint: breakpoints.xl,
    items: 4,
  },
  desktop: {
    breakpoint: breakpoints.lg,
    items: 3,
  },
  tablet: {
    breakpoint: breakpoints.md,
    items: 2,
  },
  mobile: {
    breakpoint: breakpoints.xs,
    items: 1,
  },
};

const CardPosition = styled.div`
  width: 85%;
`;

const CarouselContainer = styled.div`
  width: 97%;
  margin: 20px 0 0 auto;
`;

interface Props {
  reviews: Review[];
}
function Testimonials({ reviews }: Props) {
  const carouselRef = React.useRef<Carousel>(null);

  return (
    <div className={styles.testimonials_container}>
      <h1 className={styles.testimonial_title}>
        Ils nous font <span>confiance</span>{" "}
      </h1>
      <br />
      <CarouselContainer>
        <Carousel
          ref={carouselRef}
          swipeable={true}
          draggable={true}
          showDots={false}
          arrows={false}
          responsive={responsive}
          autoPlay
          autoPlaySpeed={5000}
          infinite
        >
          {reviews.map((review, index) => (
            <CardPosition key={index}>
              <TestimonialCard review={review} />
            </CardPosition>
          ))}
        </Carousel>
      </CarouselContainer>
    </div>
  );
}

export default Testimonials;
