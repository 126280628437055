import { GetServerSideProps, NextPage } from "next";
import { useRouter } from "next/router";
import { useEffect } from "react";
import styled from "styled-components";

import { staticApolloClient } from "../api/apollo.setup";
import { GET_ARTICLES } from "../api/graphql/article";
import { GET_OFFERS } from "../api/graphql/offer";
import { GET_REVIEWS } from "../api/graphql/review";
import HomeText from "../components/ExpandableText/HomeText";
import Footer from "../components/Footer";
import Header from "../components/Header";
import Articles from "../components/home/Articles";
import CompanyLogos from "../components/home/CompanyLogos";
import LatestOffers from "../components/home/LatestOffers";
import Companies from "../components/HomeGuest/Companies";
import Contact from "../components/HomeGuest/Contact";
import HomeSearch from "../components/HomeGuest/HomeSearch";
import LatestCompanies from "../components/HomeGuest/LatestCompanies";
import ReceiveOffers from "../components/HomeGuest/ReceiveOffers";
import Testimonials from "../components/HomeGuest/Testimonials";
import SEO from "../components/SEO";
import GuestPage from "../components/wrappers/GuestPage";
import { Page } from "../components/wrappers/PrivatePage";
import useAuth from "../hooks/useAuth";
import { Article, GetArticlesResponse } from "../types/article.type";
import { RoleEnum } from "../types/enum.types";
import {
  GetOffersRequest,
  GetOffersResponse,
  Offer,
} from "../types/offer.type";
import { GetReviewResponse, Review } from "../types/review.type";
import { getOfferImage } from "../utils/functions.utils";
import { gtmTrigger } from "../utils/gtm";
import { APPLICANT_HOME_PAGE, ENTREPRISE_OFFERS_PAGE } from "../utils/pages";

const title = "Swala | Annonces Médicales et Recrutement Santé";
const description = `
  Swala, c'est le nouveau job board dédié à la santé : annonces médicales
  et offres d'emploi santé, témoignages entreprises, conseils et bien plus
  encore.
`;

const Note = styled.div`
  width: 100%;
  background-color: var(--color-light);
  div {
    width: 55%;

    @media screen and (max-width: 992px) {
      width: 90%;
    }

    padding: 50px 0;
    margin: auto;
    text-align: center;
    color: var(--color-dark-1);
    font-family: var(--font-title-bold);
    font-size: 110%;
    line-height: 25px;
    margin-bottom: 20px;
    span {
      color: var(--color-dark-1);
    }
  }
`;

export const getServerSideProps: GetServerSideProps<{
  articles: Article[];
  reviews: Review[];
  offers: Offer[];
}> = async () => {
  const { data: articles } =
    await staticApolloClient.query<GetArticlesResponse>({
      query: GET_ARTICLES,
    });
  const { data: reviews } = await staticApolloClient.query<GetReviewResponse>({
    query: GET_REVIEWS,
  });

  const { data: offers } = await staticApolloClient.query<
    GetOffersResponse,
    GetOffersRequest
  >({
    query: GET_OFFERS,
    variables: {
      GetOffersInput: {
        currentPage: 1,
        filters: {
          sort: "RECENT",
        },
      },
    },
  });

  return {
    props: {
      articles: articles?.getArticles ?? [],
      reviews: reviews?.getAllReviews ?? [],
      offers: offers.getOffers.data ?? [],
    },
  };
};

interface PageProps {
  articles: Article[];
  reviews: Review[];
  offers: Offer[];
}

const HomePage: NextPage<PageProps> = ({ articles, offers, reviews }) => {
  const { user, isAuthenticated } = useAuth();

  const router = useRouter();
  const redirectToEntreprise = () => {
    router.push(ENTREPRISE_OFFERS_PAGE);
  };

  const redirectToWelcomePage = () => {
    router.push(APPLICANT_HOME_PAGE);
  };

  useEffect(() => {
    gtmTrigger({
      event: "datalayer-loaded",
      pageType: "home",
      page_cat: "Accueil",
    });
  }, []);

  useEffect(() => {
    if (isAuthenticated) {
      if (user?.role === RoleEnum.COMPANY) redirectToEntreprise();
      else redirectToWelcomePage();
    }
  }, [isAuthenticated]);

  return (
    <GuestPage>
      <Page background="var(--color-grey-light)">
        <SEO
          title={title}
          description={description}
          nofollow={false}
          noindex={false}
        />
        <Header />
        <HomeSearch />
        <Note>
          <div>
            Bien plus qu'une plateforme de recrutement, grâce à Swala trouvez
            tout ce qu’il vous faut : des offres d’emploi, des entreprises
            transparentes, des établissements de santé, des conseils de qualité
            et le quotidien de la santé !
          </div>
        </Note>
        <LatestOffers
          offers={offers.map((offer) => ({
            ...offer,
            imageUrl: getOfferImage(offer),
          }))}
        />
        <ReceiveOffers />
        <Companies />
        <LatestCompanies />
        {articles.length >= 4 && <Articles articles={articles} />}
        <CompanyLogos />
        {reviews.length > 3 && <Testimonials reviews={reviews} />}
        <HomeText />
        <Contact homepage />
        <Footer />
      </Page>
    </GuestPage>
  );
};

export default HomePage;
