import React from "react";
import { LazyLoadImage } from "react-lazy-load-image-component";
import styled from "styled-components";

const CompanyCardContainer = styled.div<{
  backgroundColor?: string;
  color?: string;
}>`
  width: 100%;
  height: 100%;
  background-color: ${(props) =>
    props.backgroundColor || "var(--color-dark-3)"};
  color: ${(props) => props.color || "white"};
  font-family: var(--font-title-bold);
  border-radius: 7px;
  img {
    object-fit: cover;

    width: 100%;
    height: 264px;
    border-top-left-radius: 7px;
    border-top-right-radius: 7px;
  }
`;

const PositionRelative = styled.div`
  position: relative;
  img {
    height: 230px;

    &:first-child {
      object-fit: cover;
    }

    /* medium  */
    @media screen and (max-width: 768px) {
      height: 180px;
    }
  }
`;

const CompanyCardContent = styled.div`
  padding: 0 10px;
  display: flex;
  align-items: start;

  h2 {
    font-size: 1em;
    font-weight: bolder;
    font-family: var(--font-title-bold);
  }
`;

const CompanyCardButtons = styled.div`
  display: flex;
  flex-wrap: wrap;
  /* large  */
  @media screen and (max-width: 992px) {
    margin-right: 5px;
    justify-content: flex-start;
  }
`;

const Button = styled.div`
  margin-right: 10px;
  margin-bottom: 10px;
  background-color: var(--color-primary);
  border-radius: 7px;
  border: none;
  outline: none;
  padding: 8px;

  /* large  */
  @media screen and (max-width: 992px) {
    padding: 5px 6px;
  }

  display: flex;
  align-items: center;
  justify-content: center;
  p {
    font-size: 70%;
    /* large  */
    @media screen and (max-width: 992px) {
      font-size: 60%;
    }
    margin: 0 0 0 10px;
    color: white;
    font-family: var(--font-title-bold);
  }

  img {
    width: 8px;
    height: 12px;
    /* large  */
    @media screen and (max-width: 992px) {
      width: 10px;
      height: 10px;
    }
  }
`;

const CompanyImage = styled(LazyLoadImage)`
  width: 109px !important;
  height: 109px !important;
  /* large  */
  @media screen and (max-width: 992px) {
    width: 68px !important;
    height: 68px !important;
  }
  margin-right: 10px;
  position: relative;
  top: -20px;
  border-radius: 7px;
`;

const CompanyName = styled.p`
  font-family: var(--font-title-bold);
  font-size: 100%;
  /* large  */
  @media screen and (max-width: 992px) {
    font-size: 80%;
  }
`;

const CompanyImageContainer = styled.div`
  position: relative;
`;

interface Props {
  image: string;
  location: string;
  sector: string;
  companyName?: string;
  companyImage: string;
  backgroundColor?: string;
  color?: string;
  onClick?: () => void;
}

const CompanyCard: React.FC<Props> = (props) => {
  return (
    <CompanyCardContainer
      backgroundColor={props.backgroundColor}
      color={props.color}
    >
      <PositionRelative>
        <LazyLoadImage src={props.image} alt="image" />
      </PositionRelative>

      <CompanyCardContent>
        <CompanyImageContainer>
          <CompanyImage effect="blur" src={props.companyImage} />
        </CompanyImageContainer>
        <div>
          <CompanyName>{props.companyName}</CompanyName>
          <CompanyCardButtons>
            {props.location && (
              <Button>
                <LazyLoadImage
                  src="/assets/icons/locationWhite.svg"
                  alt="location"
                />
                <p>{props.location}</p>
              </Button>
            )}

            {props.sector && (
              <Button>
                <LazyLoadImage src="/assets/icons/sector.svg" alt="vector" />
                <p>{props.sector}</p>
              </Button>
            )}
          </CompanyCardButtons>
        </div>
      </CompanyCardContent>
    </CompanyCardContainer>
  );
};

export default CompanyCard;
