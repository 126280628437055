import { LazyLoadImage } from "react-lazy-load-image-component";
import styled from "styled-components";

import { Review } from "../../../../types/review.type";

const TestimonialCardContainer = styled.div`
  background-color: white;
  margin: 20px auto;
  padding: 20px;
  width: 350px;
  /* large  */
  @media screen and (max-width: 992px) {
    width: 300px;
  }

  border-radius: 7px;
  box-shadow: 0px 4px 13px #80edd180;

  h4 {
    font-family: var(--font-title-bold);
    margin-bottom: 10px;
  }

  h5 {
    margin: 0;
  }
`;

const TestimonialContent = styled.div`
  display: flex;
  height: 247.74px;
  img {
    width: 32px;
    height: 32px;
  }
  div {
    width: 10px;
    margin: 0 10px;
    background-color: lightgrey;
  }
  p {
    font-size: 16px;
    font-weight: bold;
    line-height: 25px;
    margin: 0;
  }
`;
interface Props {
  review: Review;
}
function TestimonialCard({ review }: Props) {
  const { body, position, title } = review;
  return (
    <TestimonialCardContainer>
      <TestimonialContent>
        <LazyLoadImage src="/assets/icons/quotes.svg" alt="quotes" />
        <div />
        <p>{body}</p>
      </TestimonialContent>

      <h4>{title}</h4>
      <h5>{position}</h5>
    </TestimonialCardContainer>
  );
}

export default TestimonialCard;
