import { useRouter } from "next/router";
import { useEffect } from "react";

import useAuth from "../../hooks/useAuth";
import { APPLICANT_HOME_PAGE, ENTREPRISE_OFFERS_PAGE } from "../../utils/pages";
import Loading from "../Loading";

interface Props {
  children: React.ReactNode;
}

// If the user is authenticated then he doesn't have the right to enter the page
const GuestPage: React.FC<Props> = ({ children }) => {
  const { user, isAuthenticated } = useAuth();
  const router = useRouter();

  useEffect(() => {
    if (isAuthenticated) {
      if (user?.role === "COMPANY") router.push(ENTREPRISE_OFFERS_PAGE);
      else router.push(APPLICANT_HOME_PAGE);
    }
  }, [isAuthenticated, user?.role]);

  if (isAuthenticated) return <Loading />;
  return <>{children}</>;
};

export default GuestPage;
