import { useState } from "react";
import { LazyLoadImage } from "react-lazy-load-image-component";
import styled from "styled-components";

import { useBreakpoint } from "../../../hooks/useBreakpoint";
import { Breakpoint } from "../../../types/enum.types";
import { DEMO_URL } from "../../../utils/pages";
import Eticket from "../../Eticket";
import styles from "./LatestCompanies.module.scss";

const DemoButtonContainer = styled.div<{ backgroundColor: string }>`
  display: flex;
  align-items: center;

  a {
    text-decoration: none;
    background-color: var(--color-primary);
    color: white !important;
    border-color: transparent !important;
    font-family: var(--font-title-bold);
    border-radius: 50px;
    width: 250px;
    height: 50px;
    padding: 10px 25px 10px 25px;
    font-size: 1.2rem;
    cursor: pointer;
    text-align: center;

    /* large  */
    @media screen and (max-width: 992px) {
      font-size: 100%;
    }
  }
`;

function LatestCompanies() {
  const [expanded, setExpanded] = useState(false);
  const breakpoint = useBreakpoint();
  const isMedium =
    breakpoint.includes(Breakpoint.XS) && !breakpoint.includes(Breakpoint.LG);

  return (
    <div className={styles.latest_companies_container}>
      <div className={styles.latest_companies_content}>
        {!isMedium && <div className={styles.latest_companies_image} />}
        <div className={styles.latest_companies_details}>
          <h2>
            Vous êtes chef d'
            <Eticket
              text="entreprise"
              color="var(--color-dark-1) !important"
              backgroundColor="var(--color-primary)"
            />{" "}
            ?
          </h2>
          {isMedium && <div className={styles.latest_companies_image} />}

          <p className={styles.latest_companies_description}>
            Mettez en avant votre marque employeur, vos offres d’emploi, et
            surtout, dégotez la perle rare qui manque à votre équipe :
            directeur, cadres, responsable, assistant, stagiaires... trouvez le
            profil idéal pour compléter votre équipe grâce à l'algorithme de
            matching !
          </p>

          <div className={styles.latest_companies_text}>
            <LazyLoadImage
              src="/assets/icons/filledCapsule.svg"
              alt="capsule"
            />
            <p>C’est là que ça se passe, on attend que vous !</p>
          </div>

          <div className={styles.margin_auto}>
            <DemoButtonContainer
              backgroundColor="var(--color-dark-4)"
              className={styles.mobileButton}
            >
              <a href={DEMO_URL} target="_blank" rel="noreferrer">
                Demander une démo
              </a>
            </DemoButtonContainer>
          </div>
        </div>
        <div
          className={[styles.icon, expanded ? styles.closed : styles.open].join(
            " "
          )}
          onClick={() => {
            setExpanded(!expanded);
          }}
        >
          <LazyLoadImage src={"/assets/images/expand.svg"} />
        </div>
      </div>
      <p className={`${styles.expanded} ${expanded ? "" : "nodisplay"}`}>
        Vous êtes en manque de personnel de santé ? Pas besoin de faire appel à
        un cabinet de recrutement, complétez votre équipe médicale grâce à Swala
        !
        <br />
        <br />
        Avec l'abonnement Jobslot, déposez une offre d'emploi médicale et
        accédez à un outil de gestion pour les candidatures (vous pouvez cumuler
        plusieurs abonnements pour déposer plusieurs offres d’emploi santé en
        même temps).
        <br />
        <br />
        L'abonnement Entreprise vous permettra de mettre en avant votre
        établissement en créant votre page entreprise. Grâce à cette page,
        présentez votre établissement (métier, services, missions), votre
        secteur d'activité et vos valeurs. En plus de ça, déposez autant
        d'offres d'emploi que vous le souhaitez ! Par exemple, si vous
        recherchez un directeur d'établissement médico-social en CDI ainsi qu'un
        chef de service médical en CDD à Paris, vous pourrez poster vos deux
        offres d'emploi simultanément et suivre les candidatures grâce à l'outil
        de gestion.
        <br />
        <br />
        Si vous souhaitez avoir accès à de nombreux profils en illimité,
        l'abonnement CVthèque vous permettra de découvrir des profils de
        candidats ciblés grâce au système de matching.
      </p>
    </div>
  );
}

export default LatestCompanies;
